import React from 'react';
import './ServicesStyle.css';


const Services = () => {
  return (
    <div className="services-containerServ">
      <h1 className="services-headingServ">Our Services</h1>
      <p className="services-textServ">
        Software support services are generally technical support or break/fix services
      </p>
      <p className="services-textServ">
        that are delivered for specific software products.
      </p>

      <div className="services-images-containerServ">

      <div className="services-imageServ small-imageServ">
            <a href="/Development">
              <img
                src="https://eyeucloud.com/wp-content/uploads/2024/08/Business-Software-Development-900x506.jpg"
                alt="Software Development"
                className="imageServ"
              />
              <div className="overlayServ">
                <h3 className="overlay-titleServ">Software Development</h3>
                <p className="overlay-subtitleServ">Services</p>
              </div>
            </a>
          </div>
        <div className="services-imageServ first-imageServ">
          <a href="/Consulting">
            <img
              src="https://s2gpmrconsulting.com/wp-content/uploads/2019/12/Sourcing-to-Procurement-Consulting.jpg"
              alt="Consulting & Outsourcing"
              className="imageServ"
            />
            <div className="overlayServ">
              <h3 className="overlay-titleServ">Consulting & Outsourcing</h3>
              <p className="overlay-subtitleServ">Services</p>
            </div>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Services;
