import React, { useState } from 'react';
import './HeaderStyle.css';

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <header className={`header-containerDevH ${menuOpen ? 'active' : ''}`}>
      <div className="logo-containerDevH">
        <a href="/" className="logo-linkDevH">
          {/* <h1>Devon Tech</h1> */}
          <img src="https://i.ibb.co/qmBrfJz/Devon.jpg" alt="Logo" className="logoDevH" />
        </a>
      </div>
      <div className="hamburgerDevH" onClick={toggleMenu}>
        <div className="hamburger-lineDevH"></div>
        <div className="hamburger-lineDevH"></div>
        <div className="hamburger-lineDevH"></div>
      </div>
      <nav className={`navDevH ${menuOpen ? 'open' : ''}`}>
        <span className="cancel-iconDevH" onClick={toggleMenu}>&times;</span>
        <ul className="nav-listDevH">

        <li className="nav-itemDevH">
            <a href="About" className="nav-linkDevH">About Us</a>
            {/* <ul className="dropdownDevH">
              <li className="dropdown-itemDevH"><a href="/About">About Us</a></li>
            </ul> */}
          </li>


          <li className="nav-itemDevH">
            <a  className="nav-linkDevH">Services </a>
            <ul className="dropdownDevH">
              <li className="dropdown-itemDevH12"><a href='/Development'style={{color:"black"}}>Software Development</a></li>
              <li className="dropdown-itemDevH12"><a href='/Consulting' style={{color:"black"}}>Consulting & Outsourcing</a></li>
             
            </ul>
          </li>

          <li className="nav-itemDevH">
            <a  className="nav-linkDevH">Technologies</a>
            <ul className="dropdownDevH">
              <li className="dropdown-itemDevH"><a href="/Saptrans">Sap</a></li>
              <li className="dropdown-itemDevH"><a href="/Cloud">Cloud</a></li>
              <li className="dropdown-itemDevH"><a href="/Java">Java</a></li>
              <li className="dropdown-itemDevH"><a href="/Testing">Testing</a></li>
              <li className="dropdown-itemDevH"><a href="/Fullstack">Full-Stack</a></li>
              {/* <li className="dropdown-itemDevH"><a href="#">SAP DATA MANAGEMENT</a></li> */}
            </ul>
          </li>
          
          {/* <li className="nav-itemDevH">
            <a href="#" className="nav-linkDevH">Information Lounge</a>
            <ul className="dropdownDevH">
              <li className="dropdown-itemDevH"><a href="#">BLOG</a></li>
              <li className="dropdown-itemDevH"><a href="#">INTRODUCTION TO SAP S/4 HANA</a></li>
              <li className="dropdown-itemDevH"><a href="#">CRITICAL FACTORS FOR S/4 HANA</a></li>
              <li className="dropdown-itemDevH"><a href="#">ONSITE AND OFFSHORE ENGAGEMENT MODEL</a></li>
            </ul>
          </li> */}
          <li className="nav-itemDevH">
            <a href="/Careers" className="nav-linkDevH">Career</a>
          </li>
          <li className="nav-itemDevH">
            <a href="/Contactus" className="nav-linkDevH">Contact Us</a>
          </li>
        </ul>
      </nav>
      <div className={`sidebar-overlayDevH ${menuOpen ? 'visible' : ''}`} onClick={toggleMenu}></div>
    </header>
  );
};

export default Header;
