import React from 'react';
import './ContactusStyle.css';

const Contactus = () => {
  return (
    <div className="contactus-container">
      <div className="contactus-content">
        <div className="contactus-img">
          <img
            src="https://img.freepik.com/premium-vector/online-support-illustration-flat-design-concept-illustration-websites-landing-pages-mobile-applications-posters-banners_108061-824.jpg?size=626&ext=jpg&ga=GA1.1.230995284.1717757404&semt=ais_hybrid"
            alt="Contact Us Logo"
            className="contactus-logo"
          />
        </div>
        <div className="contactus-details">
          <h2 className="contactus-heading">Contact Us</h2>
          <p className="contactus-address">
            📍 # 3rd floor, Lumbini Society, Survey No. 55, Plot No. 108, NYN Arcade off Hitech City Main Road, next to Euro School, Gachibowli, Hyderabad, Telangana 500032.
          </p>
          <p className="contactus-email">
            ✉️ <a href="mailto:hr@devontech.in" className="contactus-email-link">hr@devontech.in</a>
          </p>
          <p className="footer-emailDevFtr">
          <span className="footer-call-iconDevFtr">📞</span>
          9603547276
        </p>

        </div>
      </div>
    </div>
  );
};

export default Contactus;
