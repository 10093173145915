import React from 'react'
import{BrowserRouter,Routes,Route} from "react-router-dom";
import Careers from './Component/CAREERS/Careers';
// import Contactus from './Component/Contactus/Contactus';
import Header from './Component/Header';
import Homepage from './Component/Homepage';
import Footer from './Component/Footer';
import About from './Component/About';
import TrustSol from './Component/TrustSol';
import Transformation from './Component/Transformation';
import Services from './Component/Services';
import Consulting from './Component/MainModule/Consulting';
import Development from './Component/Mainmodule1/Development';
import Java from './Component/Mainmodule1/Java';
import Testing from './Component/Mainmodule1/Testing';
import Fullstack from './Component/Mainmodule1/Fullstack';
import Saptrans from './Component/Mainmodule1/Saptrans';
import Cloud from './Component/Mainmodule1/Cloud';
import Lower from './Component/Mainmodule1/Lower';
import Customers from './Component/Mainmodule1/Customers';
import Application from './Component/Mainmodule1/Application';
import Tstack from './Component/Mainmodule1/Tstack';
import Contactus from './Component/Contactus/Contactus';






const App = () => {
  return (
    <div>
     <BrowserRouter>
        <Header/>
        <Routes>
         
          <Route path='/' exact element={<Homepage/>}></Route>
          <Route path='/Header' exact element={<Header/>}></Route>
          <Route path='/Footer' exact element={<Footer/>}></Route>
          <Route path='/TrustSol' exact element={<TrustSol/>}></Route>
          <Route path='/Transformation' exact element={<Transformation/>}></Route>
          <Route path='/Services' exact element={<Services/>}></Route>
          <Route path='/Consulting' exact element={<Consulting/>}></Route>
          <Route path='/Development' exact element={<Development/>}></Route>
          <Route path='/Java' exact element={<Java/>}></Route>
          <Route path='/Testing' exact element={<Testing/>}></Route>
          <Route path='/Fullstack' exact element={<Fullstack/>}></Route>
          <Route path='/Saptrans' exact element={<Saptrans/>}></Route>
          <Route path='/Cloud' exact element={<Cloud/>}></Route>
          <Route path='/Application' exact element={<Application/>}></Route>


          <Route path='/About' exact element={<About/>}></Route>
          <Route path='/Careers' exact element={<Careers/>}></Route>
          <Route path='/Contactus' exact element={<Contactus/>}></Route>




          <Route path='/Lower' exact element={<Lower/>}></Route>
          <Route path='/Customers' exact element={<Customers/>}></Route>
          <Route path='/Tstack' exact element={<Tstack/>}></Route>
         
       
          

          
     
       
          
       </Routes>
        <Footer/>
      </BrowserRouter>
      
    </div>
  )
}

export default App




